import React, { useState, useEffect } from 'react';
import { Fab } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const ScrollBackToTop = () => {
    const [show, setShow] = useState(false);

    const checkScrollTop = () => {
        if (!show && window.pageYOffset > 300) {
            setShow(true);
        } else if (show && window.pageYOffset <= 300) {
            setShow(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        window.addEventListener('scroll', checkScrollTop);
        return () => {
            window.removeEventListener('scroll', checkScrollTop);
        };
    }, [show]);

    return (
        <Fab
            color='default'
            size="small"
            aria-label="scroll back to top"
            onClick={scrollToTop}
            style={{
                position: 'fixed',
                bottom: 20,
                right: 20,
                opacity: show ? 1 : 0,
                visibility: show ? 'visible' : 'hidden',
                transition: 'opacity 0.25s ease-in-out, visibility 0.25s ease-in-out',
                color: '#282c34',
                backgroundColor: 'orange'
            }}
        >
            <KeyboardArrowUpIcon />
        </Fab>
    );
};

export default ScrollBackToTop;