import React, { useState, useEffect } from 'react';
import './CookieConsent.css';
import { Button, Dialog, DialogContent, Slide } from '@mui/material';
import CookieIcon from '@mui/icons-material/Cookie';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const CookieConsent = ({ onAccept }) => {
    const [showPopup, setShowPopup] = useState(false);

    useEffect(() => {
        const consent = localStorage.getItem('cookieConsent');
        if (!consent) {
            setShowPopup(true);
        }
    }, []);

    const handleAccept = () => {
        localStorage.setItem('cookieConsent', 'true');
        setShowPopup(false);
        if (onAccept) {
            onAccept();
        }

    };

    return (
        showPopup && (
            <Dialog
                open={true}
                TransitionComponent={Transition}
                sx={{
                    '& .MuiPaper-root': {
                        backgroundColor: '#282c34',
                        color: 'white',
                        borderRadius: '14px',
                        fontFamily: 'Neue Regrade Bold',
                        fontSize: '1.1rem'
                    }
                }}>
                <div className='cookie-title'>
                    <CookieIcon fontSize='large' />
                    <h2 >Einmal die Cookies, bitte</h2>
                </div>
                <DialogContent sx={{
                    fontFamily: 'Neue Regrade Medium',
                    lineHeight: '1.5',
                    fontSize: '1.2rem'
                }}>Wir benutzen essentielle Cookies für unsere Website, damit wir das bestmögliche Nutzererlebnis gewährleisten können.<br />
                    Sie sind nötig, damit wir die Bewertungen speichern können.</DialogContent>
                <Button
                    className='accept-button'
                    onClick={handleAccept}
                    variant='contained'
                    size='large'
                    style={{ margin: '4% 4% 7% 4%' }}
                    sx={{ color: '#282c34', backgroundColor: 'orange', fontWeight: 'bold', fontSize: '1.1rem', fontFamily: 'Neue Regrade Extrabold', transition: 'transform 0.3s ease', '&:hover': { backgroundColor: '#ed672a', transform: "scale(1.03)" }, }}>Annehmen</Button>
            </Dialog >
        )
    )
};

export default CookieConsent;