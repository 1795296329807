import { addUser } from "../../backend/data";
import { useHistory } from "react-router-dom";
const bcrypt = require('bcryptjs');

export const isValidEmail = email => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}

export const hasSpecialCharacter = password => {
    const specialCharacterRegex = /[!@#$%^&*(),.?":{}|<>]/;
    return specialCharacterRegex.test(password);
}

export const handleSignUpSubmit = async (evt, state, setState) => {
    evt.preventDefault();

    const { email, password, confirmPassword } = state;

    if (password !== confirmPassword) {
        alert("Password and confirmed password do not match");
        return;
    }
    if (!isValidEmail(email)) {
        alert("Invalid email address");
        return;
    }
    if (password.length < 6) {
        alert("Password must be at least 6 characters long");
        return;
    }
    if (!hasSpecialCharacter(password)) {
        alert("Password must contain at least one special character");
        return;
    }

    try {
        await addUser(email, password);
        alert(`A verification email has been sent to ${email}`);
    } catch (error) {
        alert('Error adding user:', error.message);
    }

    setState({
        email: "",
        password: "",
        confirmPassword: ""
    });
}

export const handleSignInSubmit = async (evt, state, setState, history) => {
    evt.preventDefault();

    const { email, password } = state;

    if (!isValidEmail(email)) {
        alert("Invalid email address");
        return;
    }

    const verificationStatus = await fetchData(`https://rate-my-meal.com/api/isver/${email}`);
    if (verificationStatus.length === 0) {
        alert(`Please sign up first`);
        return;
    }
    if (verificationStatus[0].email_verified === 0) {
        alert(`Please verify your email first`);
        return;
    }
    
    // compare hashed password with user input password
    const hashedPassword = await fetchData(`https://rate-my-meal.com/api/hashpw/${email}`);
    const isPasswordCorrect = await bcrypt.compare(password, hashedPassword[0].password);
    
    if (!isPasswordCorrect) {
        alert(`Incorrect password`);
        return;
    }

    localStorage.setItem('isLoggedIn', 'true');
    localStorage.setItem('userEmail', email);

    history.push('');


    alert(`Welcome back, ${email}`);
    

    setState({
        email: "",
        password: "",
        confirmPassword: ""
    });
}

const fetchData = async (url) => {
    try {
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching data:', error);
        return null;
    }
}