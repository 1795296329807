import MealPlan from './MealPlan';
import ScrollDownOnLoad from '../ScrollDownOnLoad';
import { useEffect, useState } from 'react';
import CookieConsent from './CookieConsent';

function Home() {
    const [cookiesAccepted, setCookiesAccepted] = useState(false);

    useEffect(() => {
        const consent = localStorage.getItem('cookieConsent');
        if (consent === 'true') {
            setCookiesAccepted(true);
        }
    }, []);

    return (
        <div className="App">
            <header className="App-header blend-into-image">
                <h1 className='gradient-text'>Rate My Meal</h1>
            </header>
            <main className='App-main'>
                <MealPlan />
            </main>
            <ScrollDownOnLoad cookiesAccepted={cookiesAccepted }/>
            <CookieConsent onAccept={() => setCookiesAccepted(true)} />
        </div>
    );
}

export default Home;