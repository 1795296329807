import React, { useEffect, useState } from 'react';

const ScrollDownOnLoad = ({ cookiesAccepted }) => {
    const [delayedScroll, setDelayedScroll] = useState(false);

    useEffect(() => {
        if (cookiesAccepted) {
            const timeout = setTimeout(() => {
                setDelayedScroll(true);
            }, 750);

            return () => clearTimeout(timeout);
        }
    }, [cookiesAccepted]);

    useEffect(() => {
        // Scroll the page down on load
        if (delayedScroll) {
            window.scrollTo({
                top: document.documentElement.scrollHeight,
                behavior: 'smooth'
            });
        }
    }, [delayedScroll]); // Empty dependency array ensures this effect runs only once after the component mounts

    return (
        <div className={delayedScroll ? 'scroll-down-animation' : ''}>
            {/* Your website content here */}
        </div>
    );
};

export default ScrollDownOnLoad;
