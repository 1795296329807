export const putData = async (m, r) => {
    const url = 'https://rate-my-meal.com/api/insert-data';
    const data = { name: m, rating: r };

    try {
        const response = await fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const result = await response.json();
        console.log('Data added:', result);
    } catch (error) {
        console.error('Error adding data:', error);
    }
};

//putData("Beispielgericht3", 4);

export const getData = async () => {
    const url = 'https://rate-my-meal.com/api/data/';

    try {
        const response = await fetch(url);

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const result = await response.json();
        console.log('Data received:', result);
        return result;
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};


export const getRating = async (meal) => {
    const url = 'https://rate-my-meal.com/api/data/' + meal;

    try {
        const response = await fetch(url);

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const result = await response.json();
        console.log('Data received:', result);
        return result;
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};
// Beispiel-Aufruf der Methode
// getRating("Beispielgericht1");

export async function addUser(username, password) {
    const url = 'https://rate-my-meal.com/api/users'; // Ersetze durch deine tatsächliche URL
    const data = { username, password };

    try {
        const response = await fetch(url, {
            method: 'PUT', // Verwende PUT, um Daten zu aktualisieren
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        if (!response.ok) {
            // Versuche, die Fehlermeldung aus dem Response-Body zu extrahieren
            const errorData = await response.text();
            console.error('Error Response Body:', errorData);
            throw new Error(`Network response was not ok: ${response.statusText}`);
        }

        // Erfolgreiche Antwort verarbeiten
        const result = await response.json();
        console.log('User updated successfully:', result);
    } catch (error) {
        console.error('Error updating user:', error);
    }
}

// Beispielaufruf der Methode
// addUser('theo.lueck@icloud.com', "myownpassword");
