import { Box, Rating } from '@mui/material';
import React, { Component } from 'react';

class AvgRatingDisplay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rating: 0
        };
    }

    render() {
        return (
            <div className='avg-rating-box'>
                <Rating
                    name="simple-controlled"
                    value={this.props.value === undefined ? this.state.rating : this.props.value}
                    size={this.props.size}
                    precision={this.props.precision}
                    onChange={this.handleRatingChange}
                    readOnly={true}
                    style={{ color: "darkorange" }}
                    disabled={this.state.isReadOnly}
                />
                <Box ml={0.5}>{"(" + this.props.counter + ")"}</Box>
            </div>
        );
    }
}

export default AvgRatingDisplay;