import React, { useState } from 'react';
import { Avatar, IconButton, Toolbar, AppBar, Drawer, List, ListItem, ListItemText, ListItemIcon, MenuItem, Tooltip, Container, Menu, Divider } from '@mui/material';
import { Home, Star, Gavel, FormatListNumbered, Menu as MenuIcon, PersonAdd, Settings, Logout, Login } from '@mui/icons-material'
import ContactMailIcon from '@mui/icons-material/ContactMail';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import LinkIcon from '@mui/icons-material/Link'
import './navbar.css';
import { Link } from 'react-router-dom';
import ScrollBackToTop from './ScrollBackToTop';

const drawerWidth = 240;

function Navbar(props) {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const loggedIn = false;

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const drawerContent = (
    <div
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <IconButton
        edge="start"
        color="inherit"
        aria-label='menu'
        onClick={(event) => {
          event.stopPropagation();
          toggleDrawer(false)(event);
        }}
        sx={{ margin: '3.2% 0 0 3%' }}
      >
        <MenuIcon />
      </IconButton>
      <List>
        {/* Home - Internal Link */}
        <ListItem button component={Link} to="/">
          <ListItemIcon>
            <Home className='navbar-icon' />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItem>

        {/* Overall Ranking - Internal Link */}
        <ListItem button component={Link} to="/oaRanking">
          <ListItemIcon>
            <FormatListNumbered className='navbar-icon' />
          </ListItemIcon>
          <ListItemText primary="Overall Ranking" />
        </ListItem>

        {/* Personal Ranking - Internal Link */}
        <ListItem button component={Link} to="/persRanking">
          <ListItemIcon>
            <Star className='navbar-icon' />
          </ListItemIcon>
          <ListItemText primary="Personal Ranking" />
        </ListItem>

        {/* Mensa Website - External Link */}
        <ListItem button component="a" href="https://stw-on.de/wolfenb%FCttel/essen/mensa" target="_blank" rel="noopener noreferrer">
          <ListItemIcon>
            <LinkIcon className='navbar-icon' />
          </ListItemIcon>
          <ListItemText primary="Mensa Website" />
        </ListItem>

        {/* Kontakt - Internal Link */}
        <ListItem button component={Link} to="/kontakt">
          <ListItemIcon>
            <ContactPageIcon className='navbar-icon' />
          </ListItemIcon>
          <ListItemText primary="Kontakt" />
        </ListItem>
      </List>
    </div>
  );

  return (
    <>
      <AppBar
        position="absolute"
        sx={{
          backgroundColor: 'transparent', boxShadow: props.applyElevation ? 4 : 0, justifyContent: 'space-between'
        }} > {/* Farbe der AppBar */}
        <Container maxWidth="xxl" sx={{ margin: '0 0 0 0' }}>
          <Toolbar disableGutters sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            {!drawerOpen && (
              <IconButton
                className='menu-icon'
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={toggleDrawer(true)}
              >
                <MenuIcon />
              </IconButton>
            )}
            <Tooltip title='Account' onClick={handleClick}>
              <IconButton
                // onClick={handleClick}
                size='small'
                sx={{ ml: 'auto' }}
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
              >
                <Avatar sx={{ width: 35, height: 35 }}></Avatar>
              </IconButton>
            </Tooltip>

            <Menu
              id="account-menu"
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              onClick={handleClose}
              keepMounted
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&::before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <MenuItem onClick={handleClose} component={Link} to="/login" >
                <ListItemIcon >
                  <Login /> {/* sx={{ color : '...' }}  to change the color of the icon */}
                </ListItemIcon>
                Log In
              </MenuItem>
              <MenuItem onClick={handleClose} component={Link} to="/settings" >
                <ListItemIcon>
                  <Settings />
                </ListItemIcon>
                Einstellungen
              </MenuItem>
              {loggedIn && (
                <>
                  <Divider />
                  <MenuItem onClick={handleClose}>
                    <ListItemIcon>
                      <Logout />
                    </ListItemIcon>
                    Logout
                  </MenuItem>
                </>
              )}
            </Menu>
          </Toolbar>
        </Container>
      </AppBar>
      <Drawer
        variant="temporary"
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        ModalProps={{
          keepMounted: true,
          BackdropProps: {
            invisible: true,
          }
        }}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            backgroundColor: 'rgba(70,70,80, 0.3)',
            color: '#ffffff',
            backdropFilter: 'blur(7px)',
          }
        }}
      >
        {drawerContent}
      </Drawer>
      <ScrollBackToTop />
    </>
  );
}

export default Navbar;
