import React, { Component } from 'react';
import Rating from '@mui/material/Rating';
import { putData } from '../backend/data';
import { Button } from '@mui/material';
class RatingComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rating: 0,
      tempRating: 0,
      isReadOnly: false,
      isButtonDisabled: false
    };
  }

  componentDidMount() {
    const currentDate = new Date().toDateString(); // Get current date as a string
    const lastAccessDate = localStorage.getItem('lastAccessDate');
    const lastCookieConsent = localStorage.getItem('cookieConsent');

    if (lastAccessDate !== currentDate) {
      localStorage.clear();
      localStorage.setItem('lastAccessDate', currentDate);
      localStorage.setItem('cookieConsent', lastCookieConsent)
    }

    const mealId = this.props.name;
    if (localStorage.getItem(`hasRated_${mealId}`)) {
      this.setState({
        isReadOnly: true,
        rating: parseInt(localStorage.getItem(`rating_${mealId}`), 10),
        tempRating: parseInt(localStorage.getItem(`rating_${mealId}`), 10)
      });
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const ratingData = {
      name: this.props.name,
      rating: this.state.rating
    };
    putData(ratingData.name, ratingData.rating)
      .then(() => {
        this.setState({ isReadOnly: true }, () => {
          localStorage.setItem(`hasRated_${ratingData.name}`, true);
          localStorage.setItem(`rating_${ratingData.name}`, this.state.rating);
          localStorage.setItem('lastAccessDate', new Date().toDateString());

          this.props.onSubmitRating(ratingData.rating);
        });
      });


  };

  handleRatingChange = (event, newValue) => {
    if (!this.state.isReadOnly) {
      this.setState({ tempRating: newValue });
    }
  };

  handleConfirmRating = (event) => {
    this.setState({ isButtonDisabled: true, rating: this.state.tempRating }, () => {
      this.handleSubmit(event);
    });
  }


  render() {
    const ratingStyles = {
      color: this.props.readonly ? 'darkorange' : 'orange',
    }
    return (
      <div style={{ flexDirection: 'column', display: 'flex', alignItems: 'center', }}>
        <Rating
          name="simple-controlled"
          value={this.state.tempRating}
          size={this.props.size}
          precision={this.props.precision}
          onChange={this.handleRatingChange}
          style={ratingStyles}
          disabled={this.state.isReadOnly}
        />
        {!this.state.isReadOnly && (
          <Button style={{ marginTop: '4%' }} variant='contained' sx={{
            color: '#282c34',
            backgroundColor: 'orange',
            height: '35px',
            fontSize: '0.8rem',
            fontFamily: 'Neue Regrade Bold',
            border: '2px solid orange',
            '&:hover': {
              backgroundColor: '#ed672a',
              border: '2px solid #ed672a'
            }
          }}
            onClick={this.handleConfirmRating}
            disabled={this.state.isButtonDisabled}
          >
            Confirm
          </Button>
        )}
      </div>
    );
  }
}

export default RatingComponent;
