import React from 'react';
import './App.css';
import './style.css';
import './components/login/style.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Navbar from './components/navbar';
import Home from './components/Home';
import oaRanking from './components/OverallRanking';
import persRanking from './components/PersonalRanking';
import imprint from './components/Imprint'
import Login from './components/login/Login';
import Settings from './components/Settings';


function App() {
  return (
    <Router>
      <div className="App"></div>
        <Navbar />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/oaRanking" component={oaRanking} />
          <Route path="/persRanking" component={persRanking} />
          <Route path="/kontakt" component={imprint} />
          <Route path="/login" component={Login} />
          <Route path="/settings" component={Settings} />	
        </Switch>
      <div/>
    </Router>
  );
}

export default App;