import '../style.css';

function PersonalRanking() {
    return (
        <div className="App">
            <header className="not-implemented">
                <h1 className="gradient-text page-header" >Personal Ranking</h1>
                <div style={{ padding: '7%' }}>
                    <p style={{ fontFamily: 'Neue Regrade Semibold', color: 'wheat' }}>Diese Funktionalität ist aktuell noch nicht realisiert</p>
                </div>
            </header>
        </div>
    );
}

export default PersonalRanking;